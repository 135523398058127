import { render, staticRenderFns } from "./FeedbackSubmissionModal.vue?vue&type=template&id=82c7d6a6&lang=pug"
import script from "./FeedbackSubmissionModal.vue?vue&type=script&lang=js"
export * from "./FeedbackSubmissionModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports