import Vue from "vue"
import ApiBase from "./_base"

Vue.prototype.$api.laborOperations = new Vue({
  name: "api.laborOperations",
  mixins: [ApiBase],
  data() {
    return {
      params: {
        status: 'inComplete',
        teams: ['any'],
        owners: ['any'],
        searchTerm: '',
      },
      config: {
        recordKey: 'laborOperation',
        recordsKey: 'laborOperations',
        recordPath: Routes.labor_operation_path,
        recordsPath: Routes.labor_operations_path,
      },
    }
  },
  asyncComputed: {
    generic: {
      default: [],
      lazy: true,
      get() {
        return this.where({types: ['LaborOperation']})
      }
    },
  },
})

export default Vue.prototype.$api.laborOperations
