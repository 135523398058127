import Vue from "vue"
import ApiBase from "./_base"

const teams = {
  name: "api.teams",
  mixins: [ApiBase],
  data() {
    return {
      config: {
        recordsKey: "teams",
        recordKey: "team",
        recordsPath: Routes.teams_path,
        recordPath: Routes.team_path,
        archivePath: Routes.archive_team_path,
        restorePath: Routes.restore_team_path,
        fetchRecords: true
      },
    }
  },
  computed: {
    shopflow() {
      return this.records.find(team => {return team.name == 'Shopflow'})
    }
  },
  asyncComputed: {
    active: {
      default: [],
      lazy: true,
      get() {
        return this.where({activeStatus: 'active'})
      }
    },
    inActive: {
      default: [],
      lazy: true,
      get() {
        return this.where({activeStatus: 'inactive'})
      }
    }
  },
  methods: {
    fetchName(teamId) {
      return utils.dig(this.fetch(teamId), 'name')
    }
  }
}

Vue.prototype.$api.teams = new Vue(teams)

export default teams
